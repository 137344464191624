@import '../../variable.scss';


.accueil__div {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin: 2em auto;
    gap: 2em;
    font-family: "adapt-variable", sans-serif;
    font-variation-settings: "wght" 500, "wdth" 100;
    cursor: default;
    overflow: hidden;

    .puzzle {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1em;
      position: relative;

      .btn-modifier {
        position: absolute;
        top: 1em;
        right: 1em;
        width: 5em;
        height: 5em;
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 50%;
        border: 0.5em solid rgba(43, 131, 37, 1);
        box-sizing: border-box;
        z-index: 20;
       
          &:hover {
              background-color: rgb(199, 199, 199);
          }
          svg {
              font-size: 2.5em;
              color: rgba(43, 131, 37, 1);
          }
      }
  
    .accueil-ferme {
      position: relative;
      max-width: 33%;
      height: 50em;
      transition: max-width 1s;
      overflow: hidden;
      opacity: 0;
      animation: cardsFadeInLeft 1s ease forwards;

      &:hover {

        .details {
          opacity: 1;
          transition: opacity 1s ease 0.5s;
        }

        .filtre {
          background-color: rgba(0,0,0,0.2);
        }

        .titre {
          height: 55%;
          transition: height 1s ease;
        }
      }

      .titre {
        padding-bottom: 1em;
        font-family: "adapt-variable",sans-serif;
        font-variation-settings: 'wdth' 100, 'wght' 300;
        font-size: 1em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: 4em;
        color: white;
        background-color: rgba($main-color, 0.8);
        z-index: 30;
        text-align: center;
        transition: height 1s ease;
        cursor: pointer;
      }

      .filtre {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        transition: background-color 1s ease;
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .details {
        font-family: "adapt-variable",sans-serif;
        font-variation-settings: 'wdth' 100, 'wght' 300;
        position: absolute;
        top: 51%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        background-color: rgba(255, 255, 255, 0.9);
        color: black;
        padding: 1em;
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: 40;
        text-align: center;
        line-height: 1.2;

          a {
            color: black;
          }
      }
    }
  
    .accueil-moutonHoublon {
      max-width: 33%;
      display: flex;
      flex-direction: column;
      gap: 0.5em;
  
      .accueil-mouton {
        position: relative;
        width: 100%;
        height: 100%;
        transition: height 1s ;
        overflow: hidden;
        opacity: 0;
        animation: cardsFadeInTop 1s ease 0.2s forwards;

        &:hover {
          height: 250%;
          transition: height 1s;

          .details {
            opacity: 1;
            transition: opacity 1s ease 0.5s;
          }

          .titre {
            height: 25em;
            top: 50%;
            transition: top 1s ease, height 1s ease;
          }

          .filtre {
            background-color: rgba(0,0,0,0.1);
          }

        }

        .titre {
          padding-bottom: 1em;
          font-family: "adapt-variable",sans-serif;
          font-variation-settings: 'wdth' 100, 'wght' 300;
          font-size: 1em;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          height: 4em;
          color: white;
          background-color: rgba($main-color, 0.8);
          z-index: 30;
          text-align: center;
          transition: height 1s ease;
          cursor: pointer;
        }
  
        .filtre {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.3);
          transition: background-color 1s ease;
        }
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .details {
          font-family: "adapt-variable",sans-serif;
          font-variation-settings: 'wdth' 100, 'wght' 300;
          position: absolute;
          top: 52%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          background-color: rgba(255, 255, 255, 0.9);
          color: black;
          padding: 1em;
          box-shadow: 0 0 10px rgba(0,0,0,0.5);
          opacity: 0;
          transition: opacity 0.3s ease;
          z-index: 40;
          text-align: center;
          line-height: 1.2; 

          a {
            color: black;
          }
        }
      }

      .accueil-houblon {
        position: relative;
        width: 100%;
        height: 100%;
        transition: height 1s ;
        overflow: hidden;
        opacity: 0;
        animation: cardsFadeInBottom 1s ease 0.3s forwards;

        &:hover {
          height: 250%;
          transition: height 1s;

          .details {
            opacity: 1;
            transition: opacity 1s ease 0.5s;
          }

          .titre {
            height: 25em;
            top: 50%;
            transition: top 1s ease, height 1s ease;
          }

          .filtre {
            background-color: rgba(0,0,0,0.1);
          }
  
        }

        .titre {
          padding-bottom: 1em;
          font-family: "adapt-variable",sans-serif;
          font-variation-settings: 'wdth' 100, 'wght' 300;
          font-size: 1em;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          height: 4em;
          color: white;
          background-color: rgba($main-color, 0.8);
          z-index: 30;
          text-align: center;
          transition: height 1s ease;
          cursor: pointer;
        }
  
        .filtre {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.3);
          transition: background-color 1s ease;
        }
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .details {
          font-family: "adapt-variable",sans-serif;
          font-variation-settings: 'wdth' 100, 'wght' 300;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          background-color: rgba(255, 255, 255, 0.9);
          color: black;
          padding: 1em;
          box-shadow: 0 0 10px rgba(0,0,0,0.5);
          opacity: 0;
          transition: opacity 0.3s ease;
          z-index: 40;
          text-align: center;
          line-height: 1.2;

          a {
            color: black;
          }
        }
      }
    }
  
    .accueil-visite {
      position: relative;
      max-width: 33%;
      height: 50em;
      transition: max-width 1s;
      overflow: hidden;
      opacity: 0;
      animation: cardsFadeInRight 1s ease 0.1s forwards;
    
      &:hover {    
        .titre {
          height: 55%;
          transition: height 1s ease;
        }
    
        .details {
          opacity: 1;
          transition: opacity 1s ease 0.5s;
        }

        .filtre {
          background-color: rgba(0,0,0,0.2);
        }
      }
    
      .titre {
        padding-bottom: 1em;
        font-family: "adapt-variable",sans-serif;
        font-variation-settings: 'wdth' 100, 'wght' 300;
        font-size: 1em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: 4em;
        color: white;
        background-color: rgba($main-color, 0.8);
        z-index: 30;
        text-align: center;
        transition: height 1s ease;
        cursor: pointer;
      }
    
      .filtre {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        transition: background-color 1s ease;
      }
    
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    
      .details {
        font-family: "adapt-variable",sans-serif;
        font-variation-settings: 'wdth' 100, 'wght' 300;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        background-color: rgba(255, 255, 255, 0.9);
        color: black;
        padding: 1em;
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: 40;
        text-align: center;
        line-height: 1.2;

        a {
          color: black;
        }
      }
    }
  }

  .facebook__div {
    position: relative;
    color: white;
    font-size: 2em;
    text-align: center;
    width: 100%;
    height: auto;
    opacity: 0;
    background-color: rgba($main-color, 1);
    animation: FacebookFadeIn 1s ease 0.1s forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0.5em;
    box-sizing: border-box;

    div {
      z-index: 3;
    }

    img {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0.2;
      object-fit:cover;
    }
  }
  
  .facebook__div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));
    z-index: 1;
  }
  
  .facebook__div > * {
    position: relative;
    z-index: 2;
  }
  
  }
  
  @keyframes annonceFadeIn {
    0% {
      opacity: 0;
      transform: translateY(-3em);

    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes cardsFadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-2em);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes cardsFadeInRight {
    0% {
      opacity: 0;
      transform: translateX(2em);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes cardsFadeInTop {
    0% {
      opacity: 0;
      transform: translateY(-2em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes cardsFadeInBottom {
    0% {
      opacity: 0;
      transform: translateY(2em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes FacebookFadeIn {
    0% {
      opacity: 0;
      transform: translateY(2em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes produitTarifsFadeIn {
    0% {
      opacity: 0;
      transform: translateY(3em);
  
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }  
  }
  
  @media (max-width: 1024px) {
    .accueil__div {
      width: 100%;
      margin: 0;
      margin-top: 0.5em;
      gap: 1em;
    }
  }
    
    @media (max-width: 768px) {
      .accueil__div {
        width: 100%;
        gap: 0.25em;

        .puzzle {
          flex-direction: column;
          gap: 0.25em;

          .accueil-ferme {
            max-width: 100%;
            height: 12em;
            transition: height 1s;
            animation: cardsFadeInRight 1s ease 0.3s forwards;

      
            &:hover {
              max-width: 100%;
              height: 30em;
              transition: height 1s;
            
              .titre {
                height: 90%;
                transition: height 1s ease;
              }
            }
          }

          .accueil-moutonHoublon {
            max-width: 100%;
            gap: 0.25em;
        
            .accueil-mouton {
              height: 12em;
              transition: height 1s ;
              animation: cardsFadeInLeft 1s ease 0.3s forwards;
      
              &:hover {
                height: 30em;
                transition: height 1s;
            
                .titre {
                  height: 80%;
                }
      
              }
      
              .titre {
                top: 50%;
              }

            }
      
            .accueil-houblon {
              height: 12em;
              transition: height 1s ;
              animation: cardsFadeInRight 1s ease 0.3s forwards;

      
              &:hover {
                height: 30em;
                transition: height 1s;
            
                .titre {
                  height: 80%;
                }
      
              }

              .titre {
                top: 50%;
              }      
            }
          }

          .accueil-visite {
            max-width: 100%;
            height: 12em;
            transition: height 1s;
            animation: cardsFadeInLeft 1s ease 0.3s forwards;
      
            &:hover {
              max-width: 100%;
              height: 30em;
              transition: height 1s;
            
              .titre {
                height: 90%;
                transition: height 1s ease;
              }
            }          
          }
        }
      }
    }
    