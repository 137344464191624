@import '../../variable.scss';

.visite {
  width: 80%;
  margin: 2em auto;
  font-family: "adapt-variable", sans-serif;
  font-variation-settings: "wght" 400, "wdth" 100;
  overflow: hidden;


  .all__section {
    position: relative;

    p {
      margin: 0;
      padding: 0;
    }

    .btn-modifier {
      position: absolute;
      top: -7em;
      right: 0;
      width: 5em;
      height: 5em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      border: 0.5em solid rgba(43, 131, 37, 1);
      box-sizing: border-box;
     
        &:hover {
            background-color: rgb(199, 199, 199);
        }
        svg {
            font-size: 2.5em;
            color: rgba(43, 131, 37, 1);
        }
    }
    
    .section {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 50%;

      .text-box {
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        text-align: center;
        width: 49%;
        height: 100%;
        padding: 0 2em; 
        box-sizing: border-box;

        div {
          a {
            color: black;
          }
        }

        &.left-top {
          animation: leftTopPhotoFadeIn 1s ease forwards;
        }

        &.right-bottom {
          animation: rightBottomPhotoFadeIn 1s ease forwards;
        }

        h2 {
          text-align: center;
        }

        p {
          text-align: center;
          line-height: 1.25em;

          a {
            color: black;
          }
        }
      }

      .photo-box {
        opacity: 0;
        width: 49%;
        height: 100%;
        overflow: hidden;
        background-color: #007bff;

        &.right-top {
          animation: rightTopPhotoFadeIn 1s ease forwards;
        }

        &.left-bottom {
          animation: leftBottomPhotoFadeIn 1s ease forwards;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.summer {
          height: 35em;
        }

        &.hop {
          height: 25em;
        }
      }
    }
  }

  .btn-reserver {
    display: inline-block;
    padding: 0.5em 1em;
    background-color: rgba($main-color,1);
    color: black;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s;
    font-variation-settings: "wght" 700, "wdth" 100;

    &:hover {
      background-color: rgb(80, 117, 1);
      color: white;
    }
  }
}

@keyframes leftTopPhotoFadeIn {
  0% {
    opacity: 0;
    transform: translate(-3em, -3em);
  }
  100% {
    opacity: 1;
    transform: translate(0 0);
  }
}

@keyframes rightBottomPhotoFadeIn {
  0% {
    opacity: 0;
    transform: translate(3em, 3em);
  }
  100% {
    opacity: 1;
    transform: translate(0 0);
  }
}

@keyframes rightTopPhotoFadeIn {
  0% {
    opacity: 0;
    transform: translate(3em, -3em);
  }
  100% {
    opacity: 1;
    transform: translate(0 0);
  }
}

@keyframes leftBottomPhotoFadeIn {
  0% {
    opacity: 0;
    transform: translate(-3em, 3em);
  }
  100% {
    opacity: 1;
    transform: translate(0 0);
  }
}

@keyframes titreFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-2em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes mobilePhotoFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-2em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes mobileTextFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .visite {
    width: 100%;
  
    .all__section {
      .section {  
        .text-box {
          padding: 0 1em;
        }
      }
    }
  
    .btn-reserver {
      display: inline-block;
      padding: 0.5em 1em;
      background-color: rgba($main-color,1);
      color: white;
      text-decoration: none;
      border-radius: 8px;
      transition: background-color 0.3s;
      font-variation-settings: "wght" 700, "wdth" 100;
  
      &:hover {
        background-color: rgb(80, 117, 1);
      }
    }
  }
}

@media (max-width: 768px) {
  .visite {
    width: 100%;

    h1 {
      font-size: 1.5em;
      padding: 1em;
      margin: 0;
      margin-bottom: 1em;
      color: white;
      font-weight: 500;
      background-color: rgba($main-color,1);
    }
  
  
    .all__section {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1em;

      .section {
        width: 100%;
        position: relative;
  
        .text-box {
          position: absolute;
          background-color: rgba(255, 255, 255, 0.9);
          width: 90%;
          padding: 1em;
          height: auto;
          left: 50%;
          transform: translate(-50%);
          z-index: 100;
          opacity: 0;

          &.left-top {
            animation: mobileTextFadeIn 1s ease 0.2s forwards;
          }
  
          &.right-bottom {
            animation: mobileTextFadeIn 1s ease 0.2s forwards;
          }          
        }
  
        .photo-box {
          width: 100%;
          height: 100%;
          opacity: 1;

          &.right-top {
            animation: mobilePhotoFadeIn 1s ease forwards;
          }
  
          &.left-bottom {
            animation: mobilePhotoFadeIn 1s ease forwards;
          }
        }
      }
    }
  
    .btn-reserver {
      background-color: rgba($main-color,1);
      width: 80%;
      text-align: center;
      border-radius: 0;
      color: black;
  
      &:hover {
        background-color: rgba(114,166,3,0.8);
      }
    }
  }
}

