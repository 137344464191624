@import '../../variable.scss';
@mixin card-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.houblon {
  width: 80%;
  margin: 2em auto;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: default;

  section {
    width: 100%;
    .title-button {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .modifierHoublon {
        position: absolute;
        top: 0;
        right: 0;
        width: 5em;
        height: 5em;
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 50%;
        border: 0.5em solid rgba(43, 131, 37, 1);
        box-sizing: border-box;
       
          &:hover {
              background-color: rgb(199, 199, 199);
          }
          svg {
              font-size: 2.5em;
              color: rgba(43, 131, 37, 1);
          }
      }
    }
  }


  .houblon-varieties {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
    animation: houblonVarietiesFadein 1s ease forwards;

    .variety {
      perspective: 1000px;
      width: 200px;
      height: 300px;

      &.out-of-stock .variety-back {
        background-color: rgba(117, 0, 0, 1);
        color: white;
      }

      .variety-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 1.2s;
        transform-style: preserve-3d;
      }

      &:hover .variety-inner {
        transform: rotateY(180deg);
      }

      .variety-face {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        border-radius: 8px;
        @include card-shadow;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;
        box-sizing: border-box;
      }
      
      .variety-front {
        background-color: #f9f9f9;
        overflow: hidden;
        cursor: pointer;

        .variety-name {
          position: absolute;
          bottom: 20%;
          font-size: 1.25em;
          font-family: "adapt-variable", sans-serif;
          font-variation-settings: "wght" 350, "wdth" 100;
          text-align: center;
          z-index: 1;
        }

        .iconeHoublon {
          position: absolute;
          top: 25%;
          width: 50%;
        }

        .stock-banner {
          position: absolute;
          background-color: rgba(255, 0, 0, 0.8);
          color: white;
          text-align: center;
          padding: 0.5em;
          font-size: 1em;
          font-family: "adapt-variable", sans-serif;
          font-variation-settings: "wght" 700, "wdth" 100;
          transform: rotate(-45deg);
          transform-origin: left top;
          top: 12.5em;
          left: -6em;
          width: 200%;
          transition: opacity 0.6s ease;
        }

        &:hover .stock-banner {
          opacity: 0;
        }

        .varietyAcide {
          position: absolute;
          bottom: 1em;
          font-family: "adapt-variable", sans-serif;
          font-variation-settings: "wght" 400, "wdth" 100;
          line-height: 1.25em;
        }

        .taux {
          font-size: 0.75em;
          font-variation-settings: "wght" 300, "wdth" 100;
        }
      }

      .variety-back {
        background-color: rgba($main-color, 1);
        color: black;
        transform: rotateY(180deg);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      
        transform: rotateY(180deg) translateZ(1px);


        .variety-description {
          font-family: "adapt-variable", sans-serif;
          font-variation-settings: "wght" 500, "wdth" 100;
          text-align: center;
          line-height: 1em;

          .varietyName {
            font-size: 1.5em;
            font-weight: 600;
          }

          .varietyDescription {
            margin-top: 1em;
          }
        }


      }
    }
  }

  .infoSupp {
    font-family: "adapt-variable", sans-serif;
    font-size: 0.85em;
    font-variation-settings: "wght" 300, "wdth" 100;
    margin-top: 3em;
    text-align: center;
  }

  .btn-commander {
    display: inline-block;
    background-color: rgba($main-color,1);
    color: black;
    padding: 1em;
    border: none;
    border-radius: 8px;
    text-decoration: none;
    font-size: 1.25em;
    font-family: "adapt-variable", sans-serif;
    font-variation-settings: "wght" 700, "wdth" 100;
    transition: background-color 0.3s;
    margin-top: 3em;
    opacity: 0;
    animation: houblonVarietiesFadein 1s ease 0.4s forwards;

    &:hover {
      background-color: rgb(80, 117, 1);
      color: white;
    }
  }
}

@keyframes houblonVarietiesFadein {
  0% {
    opacity: 0;
    transform: translateY(3em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }  
}

@keyframes houblonH2Fadein {
  0% {
    opacity: 0;
    transform: translateY(-3em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }  
}

@media (max-width: 1024px) {
  .houblon {
    width: 90%;  
  
    .houblon-varieties {
      .variety {
        .variety-front {
          .variety-name {
            bottom: 22%;
          }

        }
      }
    }
  }
}

@media (max-width: 768px) {
  .houblon {
    width: 100%;
    padding: 0;
  
    h1, h2 {
      font-size: 1.5em;
    }
  section {
    width: 100%;
  }
    .houblon-varieties {
      gap: 0.5em;
      width: 100%;

      .variety {
        width: 45%;
        height: 260px;
  
        .variety-front {
  
          .variety-name {
            bottom: 25%;
            font-size: 1em;
            font-weight: 600;
          }
          
          .iconeHoublon {
            position: absolute;
            top: 25%;
            width: 5em;
          }
  
          .stock-banner {
            font-size: 0.8em;
            transform: rotate(0deg);
            top: 1em;
            left: -50%;
          }
        }
  
        .variety-back {
          .variety-description {
            font-size: 0.9em;
  
            .varietyName {
              font-size: 1em;
              margin-bottom: 0.5em;
              font-weight: 300;
              border-bottom: 1px solid white;
            }
  
            .varietyDescription {
              margin-top: 0em;
            }
          }  
        }
      }
    }
    .order-button {
      width: 100%;

      .btn-commander {
        width: 100%;
        border-radius: 0;
        text-align: center;
        margin-top: 2em;
        padding: 1em 0;
    
        &:hover {
          background-color: rgba(114,166,3,1);
        }
      }
    }
  

  }
}
