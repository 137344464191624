.not-found {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    section {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-family: "adapt-variable",sans-serif;
            font-variation-settings: 'wdth' 100, 'wght' 400;
            font-size: 2em;
            margin: 0;
            margin-top: 2em;
            text-align: center;
            padding: 0 1em;
            line-height: 1.5em;
        }

        h2 {
            font-family: "adapt-variable",sans-serif;
            font-variation-settings: 'wdth' 100, 'wght' 200;
            margin: 0;
            margin-top: 0.5em;
            margin-bottom: 4em;

        }

        img {
            width: 35%;
        }

        a {
            font-family: "adapt-variable",sans-serif;
            font-variation-settings: 'wdth' 100, 'wght' 400;
            font-size: 1.5em;
            margin: 2.5em 0;
            margin-top: 6em;
            padding: 1em;
            background-color: rgba(114,166,3,1);
            text-decoration: none;
            color: white;

            &:hover {
                background-color: rgb(80, 117, 1);
            }
        }
    }
}

@media (max-width: 1024px) {
    .not-found {
        h1 {
            font-size: 1.5em;
        }
        h2 {
            font-size: 1em;
        }
    }
  }
  
  @media (max-width: 768px) {

    .not-found {
        img {
            width: 18em;
        }
    }

  }
  