.modale {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    
    .modale-content {
      background-color: #fff;
      padding: 20px;
      width: 80%;
      max-width: 600px;
      border-radius: 8px;
      position: relative;
      
      .close-button {
        position: absolute;
        top: 0.125em;
        right: 0.125em;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1em;
      }
      
      .modale-body {
        .section-tabs {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          
          button {
            padding: 10px;
            border: none;
            background-color: #f0f0f0;
            cursor: pointer;
            flex: 1;
            margin-right: 5px;
            
            &.active {
              background-color: #ddd;
            }
            
            &:last-child {
              margin-right: 0;
            }
          }
        }
        
        .section-form {
          input[type="text"], textarea {
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          
          input[type="file"] {
            margin-bottom: 10px;
          }
          
          img {
            max-width: 100%;
            margin-bottom: 10px;
          }
          
          button {
            background-color: #007bff;
            color: #fff;
            padding: 10px 15px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }
  