@import '../../variable.scss';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;

    .header-logo-title {

        display: flex;
        align-items: center;
        margin-left: 50px;
        gap: 1em;
        width: 40%;
        cursor: pointer;

        .logo {
            width: 5em;
        }

        .title {
            font-family: "aristelle-sans-condensed", sans-serif;
            font-size: 1.5em;
            font-weight: 500;
            font-style: normal;
        }

        .subtitle-mobile {
            display: none;
        }
    }

    .nav {
        width: 60%;
        margin-right: 50px;
        font-family: "aristelle-sans-condensed", sans-serif;
        font-size: 1.05em;
        font-weight: 500;
        font-style: normal;
        text-wrap: nowrap;
        z-index: 10;

        .nav-list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 2em;
            list-style: none;
            margin: 0;
            padding: 0;

            .nav-item {
                position: relative;

                &:hover .dropdown {
                    display: block;
                }

                a {
                    text-decoration: none;
                    color: #333;
                    padding: 10px 20px;
                    display: block;
                }

                .dropdown {
                    font-size: 0.9em;
                    display: none;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background-color: #fff;
                    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        width: 200px;

                        a {
                            text-decoration: none;
                            color: #333;
                            padding: 10px 20px;
                            display: block;

                            &:hover {
                                background-color: #f0f0f0;
                            }
                        }
                    }
                }
            }
        }
    }

    .mobile-menu {
        display: none;
    }
}

@media (max-width: 1024px) {
    .header {
      flex-direction: column;
      .header-logo-title {
        flex-direction: column;
        margin-bottom: 1em;
        
        .title {
            margin-top: 0;
            font-size: 1.5em;
        }

        .logo {
            width: 8em;
        }
      }
      .nav {
        text-wrap: nowrap;
      }
    }
  }
  
  @media (max-width: 768px) {
    .header {
        width: 100%;
        padding: 0 0.5em;
      .header-logo-title {
        width: 100%;
        margin-left: 0;

        .logo {
          width: 8em;
        }
        .title {
            width: 100%;
            text-align: center;
            font-size: 1.75em;
            margin-bottom: 0;
        }

        .subtitle-mobile {
            display: initial;
            font-family: "aristelle-sans-condensed", sans-serif;
            font-size: 1.5em;
            font-style: normal;
            text-wrap: nowrap;
            margin-top: 0;
        }
      }
      .nav {
        display: none;
        width: 100%;
        .nav-list {
            width: 100%;
            flex-wrap: wrap;
            justify-content: start;
            .nav-item {
                min-width: 24%;
                max-width: 50%;
            }
        }
      }
      .mobile-menu {
        display: initial;
        position: fixed;
        width: 60%;
        right: 0.5em;
        top: 1em;
        z-index: 1200;

        &.big {
            height: 100vh;
        }

        &.small {
            animation: menuButtonsGrowth 1s forwards;
        } 

        button {
            background-color: transparent;
            border: none;
        }
        .menu-buttons {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
            position: absolute;
            padding-top: 0.25em;
            width: 2em;
            height: 2em;
            border: none;
            font-size: 1.5em;
            color: white;
            background-color: rgba($main-color,1);
            right: 0;
            

            
                .iconMenu, .iconCloseMenu {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 1em;
                    z-index: 1500;
                    color: white;

                    &.visible {
                        display: initial;
                    }

                    &.invisible {
                        display: none;
                    }
                }
            
        }

        .menu-bar {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
            position: relative;
            box-sizing: border-box;
            background-color: rgba($main-color,1);
            color: white;
            z-index: 2;
            max-height: 0%;
            transition: height 1s, width 1s, max-height 1s, padding-bottom 1s;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 0;

            &.open {
                height: auto;
                max-height: 100%;
                padding-bottom: 1em;
            }

            ul {
                padding: 0.4em 0;
                margin: 0;
                margin-top: 0.5em;
                display: flex;
                flex-direction: column;
                gap: 0.5em;

                .section {
                    font-size: 1em;
                    color: black;
                    font-weight: 500;
                    text-align: center;
                    width: 100%;
                    margin-bottom: 0.5em;
                    padding-bottom: 0.15em;
                    border-bottom: 1px solid black;
                }
                
                li {
                    font-size: 1.1em;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    text-align: center;

                    a {
                        color: black;
                        text-decoration: none;
                    }
                }
            }
        }
      }
    }
  }
  
  @keyframes menuButtonsGrowth {
    0% {
        height: 100vh;
    }
    100% {
        height: 0vh;
    }
  }