.modale {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modale-content {
    background: white;
    padding: 2em;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    min-height: auto;
    max-height: 80%;
    position: relative;
    box-sizing: border-box;
}

.modale-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.modale-header button {
    margin-right: 10px;
}

.modale-header .close-button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.modale-body {
    display: flex;
    flex-direction: column;
}

.modale-body input,
.modale-body textarea,
.modale-body select {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modale-body button {
    padding: 10px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
}

.modale-body button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.modale-body textarea {
    resize: vertical;
}
