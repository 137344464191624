.footer {
  margin-top: 2em;
  p {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  h3 {
    margin: 0;
    padding: 0.25em 0;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: white;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
  width: 100%;
    background-color: rgba(0,0,0,0.7);
    color: #fff;

    .footer-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1em 2em;

      .footer-section {
        width: 33%;

      }
    }
    .footer-bottom {
      text-align: center;
      font-weight: 640;
      padding: 1em 0;
    }
  }
  
  @media (max-width: 1024px) {
    .footer {
      .footer-content {
        flex-direction: column;
        align-items: center;
        padding: 0;
        
        .footer-section {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .footer {
      .footer-content {
        padding: 1em;
        .footer-section {
          h4 {
            font-size: 1.1em;
          }
          p, a {
            font-size: 0.9em;
          }
        }
      }
    }
  }
  