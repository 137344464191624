.bandeau {
    width: 100%;
    height: 25em;
    z-index: 50;
  
    .bandeau-title {
      text-align: center;
      margin-bottom: 0.5em;
    }
  
    .bandeau-content {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
  
      .bandeau-image {
        flex: 1;
        display: flex;
        width: 70%;
        height: 20em;
        opacity: 0;
        animation: bandeauImageFadeIn 1s ease forwards;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          filter: brightness(0.9);
          opacity: 0.6;

          &.cadreImage {
            object-position: center 50%; 
          }
        }
  
        .bandeau-controls {
          .modifierBandeauImg,
          .saveBanniere,
          .cancelBanniere,
          .custom-file-upload {
            position: absolute;
            z-index: 10;
            width: 5em;
            height: 5em;
            background-color: white;
            border: none;
            cursor: pointer;
            border-radius: 0.5em;
           
            &:hover {
              background-color: rgb(199, 199, 199);
            }
            svg {
              font-size: 3em;
              color: rgba(43, 131, 37, 1);
            }
          }

          .inputCadreBanniere {
            position: absolute;
            bottom: 0.5em;
            right: 3em;
            z-index: 10;
            width: 2em;
            height: 2em;
            border-radius: 0.5em;
            border: 0.15em solid rgba(43, 131, 37, 1);
            font-size: 2em;
            box-sizing: border-box;
          }
  
          .modifierBandeauImg,
          .saveBanniere {
            top: 1em;
            right: 1em;
          }
  
          .cancelBanniere {
            top: 7em;
            right: 1em;
          }
  
          .custom-file-upload {
            bottom: 1em;
            right: 1em;
            width: 4em;
            height: 4em;
            display: flex;
            justify-content: center;
            align-items: center;
          }
  
          .file-input {
            display: none;
          }
        }
      }
  
      .bandeau-text {
        width: 45%;
        padding: 1em;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.9);
        top: 7em;
        z-index: 60;
        opacity: 0;
        font-family: "adapt-variable", sans-serif;
        font-variation-settings: "wght" 500, "wdth" 90;
        line-height: 1.75em;
        font-size: 0.9em;
  
        &.left {
          left: -5em;
          animation: textLeftFadeIn 1s ease 0.2s forwards;
        }
  
        &.center {
          left: 50%;
          transform: translate(-50%);
          animation: textCenterFadeIn 1s ease 0.2s forwards;
        }
  
        &.right {
          right: -5em;
        }
  
        .edit-input, .edit-textarea {
          width: 100%;
          padding: 0.5em;
          margin: 0;
          border: none;
          font-family: "adapt-variable", sans-serif;
          font-variation-settings: "wght" 500, "wdth" 90;
          text-align: center;
          resize: none;
          outline: none;
          color: black;
          box-sizing: border-box;
        }
  
        .edit-input {
          font-size: 2em;
          min-height: 3em;
        }
  
        .edit-textarea {
          font-size: 1em;
          min-height: 10em;
          text-align: start;
        }
      }
    }
  }
  
  @keyframes bandeauImageFadeIn {
    0% {
      opacity: 0;
      transform: translateY(-2em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes textLeftFadeIn {
    0% {
      opacity: 0;
      transform: translateX(-2em);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes textCenterFadeIn {
    0% {
      opacity: 0;
      top: 9em;
    }
    100% {
      opacity: 1;
      top: 7em;
    }
  }

  @keyframes textMobileFadeIn {
    0% {
        opacity: 0;
        top: 9em;
    }
    100% {
        opacity: 1;
        top: 5%;
        height: 80%;
    }
}
  
  @media (max-width: 1024px) {
    .bandeau {
      height: 20em;
      margin-bottom: 1em;
  
      .bandeau-content {
        .bandeau-image {
          width: 100%;
          height: 20em;
          position: relative;
  
          img {
            object-fit: cover;
          }
        }
  
        .bandeau-text {
          width: 90%;
          z-index: 10;
          opacity: 0;
  
          .bandeau-title {
            font-size: 2em;
          }
  
          &.center, &.left, &.right {
            left: 50%;
            transform: translate(-50%);
            animation: textMobileFadeIn 1s ease 0.2s forwards;
          }
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .bandeau {
      height: 20em;
  
      .bandeau-content {
        .bandeau-image {
          width: 100%;
          height: 20em;
        }
  
        .bandeau-text {
          width: 80%;
          z-index: 10;
          overflow-y: auto;
          padding: 1em;
  
          .bandeau-title {
            font-size: 1.25em;
          }
  
          &.center, &.left, &.right {
            left: 50%;
            transform: translate(-50%);
            animation: textMobileFadeIn 1s ease 0.2s forwards;
          }
        }
      }
    }
  }
  