.mentions {
    padding: 20px;

    .mentions-content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        position: relative;

        .btn-modifier {
            position: absolute;
            top: -7em;
            right: 0;
            width: 5em;
            height: 5em;
            background-color: white;
            border: none;
            cursor: pointer;
            border-radius: 50%;
            border: 0.5em solid rgba(43, 131, 37, 1);
            box-sizing: border-box;
           
              &:hover {
                  background-color: rgb(199, 199, 199);
              }
              svg {
                  font-size: 2.5em;
                  color: rgba(43, 131, 37, 1);
              }
          }

        .mention-card {
            width: calc(33.333% - 20px);
            min-width: 300px;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            overflow: hidden;
            transition: transform 0.3s;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            opacity: 0;
            animation: mentionsCardFadein 1s ease forwards;

            &:hover {
                transform: translateY(-5px);
            }

            .mention-thumbnail {
                width: 100%;
                height: 180px;
                background-size: cover;
                background-position: center;
            }

            .mention-info {
                padding: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-grow: 1;

                h3 {
                    margin: 0 0 10px;
                    font-size: 18px;
                    min-height: 2.5em;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }

                p {
                    margin: 0 0 15px;
                    color: #666;
                }

                a {
                    color: #007bff;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .mentions {
        padding: 10px;

        .mentions-content {
            gap: 10px;

            .mention-card {
                width: 100%;
                min-width: unset;
                border-radius: 0;
                border: none;
                background: none;
                box-shadow: none;
                padding: 0;

                &:hover {
                    transform: none;
                }

                .mention-thumbnail {
                    height: 200px;
                }

                .mention-info {
                    padding: 10px;

                    h3 {
                        font-size: 16px;
                    }

                    p {
                        font-size: 14px;
                        color: #888;
                    }

                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@keyframes mentionsCardFadein {
    0% {
      opacity: 0;
      transform: translateY(3em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }  
  }