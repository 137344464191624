.modale {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    &-content {
      background: #fff;
      padding: 20px;
      border-radius: 5px;
      max-width: 500px;
      width: 100%;
      position: relative;
    }
  
    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
  
      button {
        background: none;
        border: none;
        font-size: 18px;
        cursor: pointer;
  
        &.active {
          font-weight: bold;
          color: #000;
        }
      }
    }
  
    &-body {
      display: flex;
      flex-direction: column;
  
      input[type="text"],
      textarea {
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
      }

      textarea {
        min-height: 10em;
      }
  
      input[type="file"] {
        margin-bottom: 10px;
      }
  
      button {
        background: #007bff;
        color: #fff;
        border: none;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
  
        &:hover {
          background: #0056b3;
        }
      }
    }
  
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 10px;
      cursor: pointer;
    }
  }
  