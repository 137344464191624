.histoire {
  width: 80%;
  margin: 2em auto;
  font-family: "adapt-variable", sans-serif;
  font-variation-settings: "wght" 500, "wdth" 100;

  .timeline-container {
    position: relative;
    margin: 2em 0;
    width: 100%;
    animation: histoireFadeIn 1.5s ease forwards;
    margin-top: 0;

    .btn-modifier {
      position: absolute;
      top: 0em;
      right: 0;
      width: 5em;
      height: 5em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      border: 0.5em solid rgba(43, 131, 37, 1);
      box-sizing: border-box;
     z-index: 20;
        &:hover {
            background-color: rgb(199, 199, 199);
        }
        svg {
            font-size: 2.5em;
            color: rgba(43, 131, 37, 1);
        }
    }
    .background-photo {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 630px;
      background-color: #ccc;

      img {
        position: absolute;
        width: 100%;
        height: 630px;
        object-fit: cover;
        filter: grayscale(1);
        opacity: 0;
        z-index: 0;
        transition: opacity 0.8s ease-in-out;
      }

      img.selected {
        opacity: 1;
        z-index: 1;
      }

      .content-date {
        position: absolute;
        width: 500px;
        height: auto;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
        right: 5%;
        display: flex;
        flex-direction: column;
        padding: 1em;
        color: #fff;
        overflow: hidden;
        transition: height 1s ease;
        line-height: 1.2;


        .content {
          animation: histoireContentfadeIn 1s ease;

          .bold {
            font-size: 1.25em;
            font-variation-settings: "wght" 600, "wdth" 100;
          }

          a {
            color: white;
            text-decoration: none;
          }
        }
      }

      .frise__div {
        position: absolute;
        width: 100%;
        height: 10%;
        bottom: 0;
        z-index: 15;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .frise {
          position: absolute;
          top: 0;
          width: 100%;
          height: 5px;
          background-color: #fff;
          opacity: 0.6;
        }

        .date {
          position: absolute;
          width: 20px;
          height: 20px;
          background-color: white;
          top: -0.5em;
          border-radius: 50%;
          cursor: pointer;
          transition: height 0.6s ease, border-radius 0.6s ease, top 0.6s ease;

          .year {
            position: absolute;
            font-size: 1.5em;
            top: -1.5em;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            white-space: nowrap;
            color: white;
            transition: opacity 0.6s ease;
          }

          .month {
            position: absolute;
            font-size: 1.25em;
            top: -3em;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            white-space: nowrap;
            color: white;
            transition: opacity 0.6s ease;
          }

          &.active {
            height: 40px;
            border-radius: 5%;
            top: -1em;
            background-color: rgba(0, 0, 0, 0.9);

            .year, .month {
              opacity: 1;
            }
          }

          &:hover {
            height: 40px;
            border-radius: 5%;
            top: -1em;

            .year, .month {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@keyframes histoireContentfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes histoireFadeIn {
  0% {
    opacity: 0;
    transform: translateY(3em);

  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }  
}

@media (max-width: 1024px) {
  .histoire {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .histoire {
    width: 100%;
    overflow: hidden;

    .timeline-container {
      .background-photo {
        .content-date {
          width: 60%;
        }
        .frise__div {
          .date {
            &.active {
              .month {
                display: none;
              }
              .year {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
