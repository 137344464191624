@import '../../variable.scss';

.produit {
  width: 80%;
  margin: 0 auto;
  padding: 2em 0;
  cursor: default;

  .bandeau {
    .bandeau-content {
      .bandeau-image  {
        img {
          object-position: center -22em;
        }
      }
    }
  }

  .produit-content {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-bottom: 3em;



    .produit-info {
      position: relative;
      background-color: #f9f9f9;
      padding: 2em;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      color: white;
      overflow: hidden;

      .photo-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        z-index: 0;

        .photo-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 1;
        }
      }

      .info-text {
        position: relative;
        z-index: 2;
        p {
          margin-bottom: 1em;
          line-height: 1.6;
        }
      }
    }

    .produit-tarifs {
      display: flex;
      gap: 2em;
      justify-content: center;
      opacity: 0;
      animation: produitTarifsFadeIn 1.5s ease 0.4s forwards;
      position: relative;

      .btn-modifier {
        position: absolute;
        top: -7em;
        right: 0;
        width: 5em;
        height: 5em;
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 50%;
        border: 0.5em solid rgba(43, 131, 37, 1);
        box-sizing: border-box;
       
          &:hover {
              background-color: rgb(199, 199, 199);
          }
          svg {
              font-size: 2.5em;
              color: rgba(43, 131, 37, 1);
          }
      }
      

      .produit-tarif-item {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1em;
        flex: 1;
        background-color: rgba($main-color,0.4);
        padding: 3em 2em;
        border-radius: 8px;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.2);

        .itemNomPrixPrecision {
          display: flex;
          flex-direction: column;
          
          p {
            margin: 0;
            padding: 0;
          }

          .precision {
            font-style: italic;
          }
        }

        .btn-commander {
          position: absolute;
          box-sizing: border-box;
          bottom: -10%;
          left: 50%;
          transform: translate(-50%);
          background-color: rgba($main-color,1);
          color: black;
          padding: 1em 2em;
          border: none;
          border-radius: 8px;
          text-decoration: none;
          font-size: 1.2em;
          transition: background-color 0.3s;
          font-size: 1.25em;
          text-align: center;
          margin-top: 2em;
          font-family: "adapt-variable", sans-serif;
          font-variation-settings: "wght" 700, "wdth" 100;
  
          &:hover {
            background-color: rgb(80, 117, 1);
            color: white;
          }
        }

        h2 {
          margin-top: 0;
          font-size: 1.75em;
          color: black;
          font-family: "adapt-variable", sans-serif;
          font-variation-settings: "wght" 300, "wdth" 100;
          text-align: center;
          padding: 0.5em 0;
          background-color: rgba($main-color,1);
        }

        p {
          margin-bottom: 0.5em;
          line-height: 1.6;
          color: black;
          font-family: "adapt-variable", sans-serif;
          font-variation-settings: "wght" 400, "wdth" 100;
        }


      }
    }
  }
}

.presenceMarche {
  margin: 0 auto;
  margin-top: 5em;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  font-family: "adapt-variable", sans-serif;
  font-size: 0.9em;
  line-height: 1.5em;


  .mainSpan {
    font-weight: bold;
  }

  a {
    color: black;
    text-wrap: nowrap;
    margin-left: 0.5em;
  }
}

@keyframes produitTarifsFadeIn {
  0% {
    opacity: 0;
    transform: translateY(3em);

  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }  
}

@media (max-width: 768px) {
  .produit {
    width: 100%;

    .bandeau {
      .bandeau-content {
        .bandeau-image  {
          img {
            object-position: center;
          }
        }
      }
    }

    .produit-content {
  
      .produit-tarifs {
        display: flex;
        flex-direction: column;
        gap: 5em;

        .produit-tarif-item {
          border-radius: 0;

          
      .btn-commander {
        width: 100%;
        bottom: -3em;
        border-radius: 0;
      }

      h3 {
        width: 100%;
        font-size: 1.5em;
        padding: 0.5em 0;
        margin: 0;
        margin-bottom: 0.5em;
        color: white;
        font-weight: 300;
        background-color: rgba(114,166,3,1);      
      }

      }

    }
  }
  .presenceMarche {
    width: 90%;

    .marche {
      flex-direction: column;
    }
  }  
}
}