.mouton {
  width: 80%;
  margin: 2em auto;
  font-family: "adapt-variable", sans-serif;
  font-variation-settings: "wght" 400, "wdth" 100;
  overflow: hidden;

  a {
    color: black;
  }

  .mouton-content {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    position: relative;

    .btn-modifier {
      position: absolute;
      top: -7em;
      right: 0;
      width: 5em;
      height: 5em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      border: 0.5em solid rgba(43, 131, 37, 1);
      box-sizing: border-box;
     
        &:hover {
            background-color: rgb(199, 199, 199);
        }
        svg {
            font-size: 2.5em;
            color: rgba(43, 131, 37, 1);
        }
    }
  }

  .mouton-card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 4px;
    opacity: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    animation: moutonTilesFadeIn 1.5s ease forwards;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid transparent;
      border-radius: 8px;
      transition: border-color 0.3s ease;
    }

    &:hover::before {
      border-color: rgba(114,166,3, 0.8);
    }

    .mouton-image {
      width: 100%;
      height: 150px;
      background-size: 100%;
      background-repeat: no-repeat;
      filter: brightness(0.8);
      opacity: 0.9;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .mouton-title {
      padding: 1em;
      text-align: center;
      position: relative;

      h2 {
        margin: 0;
        font-size: 1.5em;
        color: #333;
      }

      .mouton-text {
        margin-top: 1em;
        overflow: hidden;
        transition: height 0.8s ease;
      }

      .mouton-text p {
        margin: 0;
        line-height: 1.75em;
      }
    }
  }
}

@keyframes moutonTilesFadeIn {
  0% {
    opacity: 0;
    transform: translateY(3em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (max-width: 1024px) {
  .mouton {
    width: 90%;
    h1 {
      font-size: 1.75em;
    }

    .mouton-card .mouton-title h2 {
      font-size: 1.25em;
    }

    .mouton-card .mouton-title .mouton-text {
      font-size: 1em;
    }
  }
}

@media (max-width: 768px) {
  .mouton {
    width: 100%;

    .intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2em;
  
      h1 {
        font-size: 1.5em;
        padding: 1em;
        margin: 0;
        margin-bottom: 1em;
        color: white;
        font-weight: 500;
        background-color: rgba(43,131,37,1);
      }
  
      p {
        width: 90%;
        text-align: center;
        line-height: 1.25em;
        font-family: "adapt-variable", sans-serif;
        font-variation-settings: "wght" 300, "wdth" 100;
        font-size: 1.15em;
      }
    }


    .mouton-card {
      border-radius: 0;

      &:before {
        border-radius: 0;
      }

      .mouton-image {
        border-radius: 0;
      }

      .mouton-title {
        padding: 0.5em 1em;

        h2 {
          font-size: 1.2em;
        }

        .mouton-text {
          font-size: 0.9em;
        }
      }
    }
  }
}
