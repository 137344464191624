.annonce {
    width: 100%;
    position: relative;
    box-sizing: border-box;
  
    .div__annonce, .div__modifierAnnonce {
      font-family: "adapt-variable", sans-serif;
      font-variation-settings: 'wdth' 100, 'wght' 300;
      width: 100%;
      height: 5em;
      opacity: 0;
      background-color: rgba(213, 205, 60, 1);
      color: white;
      text-align: center;
      font-size: 1.25em;
      animation: annonceFadeIn 1s ease 0.1s forwards;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
  
      a {
        font-size: 0.8em;
        color: white;
        margin-top: 0.5em;
      }
  
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));
        z-index: 1;
      }
  
      & > * {
        position: relative;
        z-index: 2;
      }
    }
  
    .modifierAnnonce {
      position: absolute;
      right: 1em;
      top: 1em;
      width: 5em;
      height: 5em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 0.5em;
     
        &:hover {
            background-color: rgb(199, 199, 199);
        }
        svg {
            font-size: 3em;
            color: rgba(43, 131, 37, 1);
        }
    }
  
    .div__modifierAnnonce {

      .annonceText {
        width: 90%;
        display: flex;
        align-items: center;
        gap: 0.5em;
        font-size: 0.8em;
        input {
            font-family: "adapt-variable", sans-serif;
            font-variation-settings: 'wdth' 100, 'wght' 300;
            color: white;
            text-align: center;
            font-size: 1.25em;
            padding: 0.25em;
            width: 70%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.6);
            border: none;
        }
      }
  
      .lien__div {
        font-size: 0.8em;
        margin-top: 0.5em;
        width: 84.3%;
        display: flex;
        align-items: center;
        gap: 1em;
  
        .lienText {
            font-family: "adapt-variable", sans-serif;
            font-variation-settings: 'wdth' 100, 'wght' 300;
            color: white;
            text-align: center;
            font-size: 1em;
            padding: 0.25em; 
            width: 30%;
            background-color: rgba(255, 255, 255, 0.6);
            border: none;
        }
  
        .lienPage {
            font-family: "adapt-variable", sans-serif;
            font-variation-settings: 'wdth' 100, 'wght' 300;
            color: white;
            text-align: center;
            font-size: 1em;
            padding: 0.25em; 
            width: 19.8%;
            background-color: rgba(255, 255, 255, 0.6);
            border: none;
        }

        .lienPage option {
            background-color: #f0f0f0;
            color: #333;
          }
          
      }
  
      .saveAnnonce {
        position: absolute;
        right: 7em;
        top: 1em;
        width: 5em;
        height: 5em;
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 0.5em;

        &:hover {
            background-color: rgb(199, 199, 199);
        }

        svg {
            font-size: 3em;
            color: rgba(43, 131, 37, 1);
        }
      }

      .cancelAnnonce {
        position: absolute;
        right: 1em;
        top: 1em;
        width: 5em;
        height: 5em;
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 0.5em;

        &:hover {
            background-color: rgb(199, 199, 199);
        }

        svg {
            font-size: 3em;
            color: rgba(43, 131, 37, 1);
        }
      }
    }
  }
  