.about {
  width: 80%;
  margin: 0 auto;
  padding: 2em 0;
  cursor: default;
  font-family: "adapt-variable",sans-serif;
  font-variation-settings: 'wdth' 100, 'wght' 300;

  .team {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
    position: relative;

    .btn-modifier {
      position: absolute;
      top: -7em;
      right: 0;
      width: 5em;
      height: 5em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      border: 0.5em solid rgba(43, 131, 37, 1);
      box-sizing: border-box;
     
        &:hover {
            background-color: rgb(199, 199, 199);
        }
        svg {
            font-size: 2.5em;
            color: rgba(43, 131, 37, 1);
        }
    }

    .team-member {
      width: 48%;
      box-sizing: border-box;
      background-color: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 1em;
      display: flex;
      align-items: center;
      transition: transform 0.3s ease, box-shadow 0.3s;
      opacity: 0;
      animation: teamMemberFadeIn 1s ease forwards;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        cursor: default;
      }

      .photo {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 1em;
        margin-right: 1em;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5%;
        }
      }

      .info {
        flex: 1;
        
        h3 {
          margin: 0 0 0.5em;
          font-size: 1.5em;
          color: black;
        }

        p {
          margin: 0;
          line-height: 1.4;
        }
      }
    }
  }
}

@keyframes aboutH1FadeIn {
  0% {
    opacity: 0;
    transform: translateY(-3em);

  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }  
}

@keyframes teamMemberFadeIn {
  0% {
    opacity: 0;
    transform: translateY(3em);

  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }  
}

@media (max-width: 1024px) {
  .about {
    width: 90%;

    .bandeau {
      .bandeau-content {
        .bandeau-image {
          img {
            object-position: center;
          }
        }
      }
    }
  
    h2 {
      font-size: 2em;
    }
  
    .team {
      width: 100%;
  
      .team-member {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .about {
    width: 100%;
  
    h2 {
      margin-top: 1.5em;
      font-size: 1.75em;
    }
  
    .team {
      width: 100%;
  
      .team-member {
        width: 100%;
        border-radius: 0;
        display: flex;
        flex-direction: column;
  
        .photo {
          width: 100%;
          height: 22em;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 1em;
          margin-right: 0em;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 0;
          }
        }
  
        .info {
          flex: 1;
          
          h3 {
            text-align: center;
            margin: 0.5em 0.5em;
            font-size: 1.5em;
            color: black;
          }
  
          p {
            text-align: center;
            margin: 0;
            line-height: 1.4;
          }
        }
      }
    }
  }
}
