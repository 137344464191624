.modale {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    .modale-content {
      background: white;
      padding: 2em;
      border-radius: 8px;
      width: 80%;
      max-width: 500px;
      min-height: auto;
      max-height: 80%;
      position: relative;
      box-sizing: border-box;
    }
  
    .modale-header {
      display: flex;
      justify-content: space-around;
      margin-bottom: 1em;
  
      button {
        background: white;
        color: black;
        border: 1px solid black;
        border-radius: 4px;
        padding: 0.5em 1em;
        cursor: pointer;
        transition: background 0.3s, color 0.3s;
  
        &.active {
          background: green;
          color: white;
        }
      }
    }
  
    .modale-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      input,
      textarea,
      select {
        width: 100%;
        margin-bottom: 1em;
        padding: 0.5em;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
  
      textarea {
        resize: none;
        height: 5em;
      }
  
      button {
        margin-top: 2em;
        padding: 0.5em 1em;
        background: green;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        align-self: center;
      }
    }
  
    .close-button {
      position: absolute;
      top: 0em;
      right: 0em;
      background: none;
      border: none;
      color: black;
      cursor: pointer;
      font-size: 0.5em;
    }
  }
  