.banniere {
  position: relative;
  text-align: center;
  color: white;
  margin-top: 20px;
  z-index: 1;
  width: 100%;
  height: 20em;
  overflow: hidden;

  .modifierBanniere {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 10;
    width: 5em;
    height: 5em;
    background-color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.5em;
   
      &:hover {
          background-color: rgb(199, 199, 199);
      }
      svg {
          font-size: 3em;
          color: rgba(43, 131, 37, 1);
      }
  }

  .saveBanniere {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 10;
    width: 5em;
    height: 5em;
    background-color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.5em;
   
      &:hover {
          background-color: rgb(199, 199, 199);
      }
      svg {
          font-size: 3em;
          color: rgba(43, 131, 37, 1);
      }  }

  .cancelBanniere {
    position: absolute;
    top: 7em;
    right: 1em;
    z-index: 10;
    width: 5em;
    height: 5em;
    background-color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.5em;
   
      &:hover {
          background-color: rgb(199, 199, 199);
      }
      svg {
          font-size: 3em;
          color: rgba(43, 131, 37, 1);
      }  
    }

  .banniere-image {
    position: relative;
    width: 100%;
    height: 100%;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0.4) 100%);
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;

      &.cadreImage {
        object-position: center var(--cadre-image-position, 50%);
      }
    }

    .file-input {
      display: none;
    }

    .custom-file-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 1em;
      right: 1em;
      z-index: 10;
      width: 4em;
      height: 4em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 0.5em;
     
        &:hover {
            background-color: rgb(199, 199, 199);
        }
        svg {
            font-size: 3em;
            color: rgba(43, 131, 37, 1);
        }
    }

    .inputCadreBanniere {
      position: absolute;
      bottom: 0.5em;
      right: 3em;
      z-index: 10;
      width: 2em;
      height: 2em;
      border-radius: 0.5em;
      border: 0.15em solid rgba(43, 131, 37, 1);
      font-size: 2em;
      box-sizing: border-box;
    }

    .banniere-text {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 3;

      h1 {
        font-family: "aristelle-sans-condensed", sans-serif;
        font-size: 3.5em;
        font-style: normal;
        margin: 0;
        padding: 10px 0;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
      }

      p {
        font-family: "aristelle-sans-condensed", sans-serif;
        font-size: 2em;
        font-style: normal;
        margin-top: 10px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
      }

      .edit-input {
        font-family: "aristelle-sans-condensed", sans-serif;
        font-size: 3.5em;
        font-style: normal;
        margin-bottom: 0.5em;
        padding: 10px 0;
        border: none;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        background: rgba(255,255,255,0.2);
        color: white;
        text-align: center;
        width: 100%;
      }

      .edit-subtitle {
        font-family: "aristelle-sans-condensed", sans-serif;
        font-size: 2em;
        font-style: normal;
        margin-top: 0;
        border: none;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
        background: rgba(255,255,255,0.2);
        color: white;
        text-align: center;
        width: 100%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .banniere {
    height: 15em;
    .banniere-image {
      img {
        width: 100%;
        height: 15em;
        object-position: center;
        object-fit: cover;
        opacity: 0.9;
      }
      .banniere-text {
        .banniere__title {
          font-size: 3em;
        }
        .banniere__p {
          font-size: 1.5em;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .banniere {
    display: none;
  }
}
