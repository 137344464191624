.bandeau-admin {
  background: black;
  padding-bottom: 1em;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 

  p {
    margin: 0;
    color: white;
    padding: 1em;
    text-align: center;
  }

  a {
    color: white;
  }

  .push-button {
    margin-top: 1em; 
  }
}

.modale {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modale-content {
    background: white;
    padding: 2em;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    position: relative;
    box-sizing: border-box;

    .modale-header {
      position: relative;
      width: 100%;

      .close-button {
        position: absolute;
        top: -1em;
        right: -1em;
        background: none;
        border: none;
        color: black;
        cursor: pointer;
        font-size: 3em;
      }
    }

    .modale-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      div {
        width: 100%;
        display: flex;
        gap: 1em;

        span {
          width: 40%;
        }
      }

      input {
        width: 100%;
        margin-bottom: 1em;
        padding: 0.5em;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      button {
        margin-top: 2em;
        padding: 0.5em 1em;
        background: green;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
