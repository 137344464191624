@import '../../variable.scss';

.section-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
  position: relative;
  width: 100%;

  div {
    display: flex;
    gap: 1em;
  }

  .edit-btn {
    width: 5em;
    height: 5em;
    background-color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.5em;
    border: 0.5em solid rgba(43, 131, 37, 1);
    box-sizing: border-box;
   
    &:hover {
      background-color: rgb(199, 199, 199);
    }
    svg {
      font-size: 3em;
      color: rgba(43, 131, 37, 1);
    }
  }

  h1 {
    font-family: "adapt-variable", sans-serif;
    font-variation-settings: "wght" 500, "wdth" 100;
    text-align: center;
    margin-top: 1em;
    font-size: 2em;
    color: #333;
    opacity: 0;
    animation: moutonTitleFadeIn 2s ease 0.2s forwards;
  }

  p {
    width: 80%;
    text-align: center;
    line-height: 1.5em;
    font-family: "adapt-variable", sans-serif;
    font-variation-settings: "wght" 300, "wdth" 100;
    font-size: 1.25em;
    opacity: 0;
    animation: moutonTextFadeIn 2s ease 0.5s forwards;
    margin-bottom: 4em;
  }

  .edit-input {
    width: 60%;
    font-size: 2em;
    margin: 1em 0;
    padding: 0.5em;
    text-align: center;
  }

  .edit-textarea {
    width: 80%;
    height: 6em;
    font-size: 1.25em;
    margin: 1em 0;
    padding: 0.5em;
    text-align: center;
    line-height: 1.5em;
    resize: none;
    font-family: "adapt-variable", sans-serif;
    font-variation-settings: "wght" 300, "wdth" 100;
  }

  .controls {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;

    .save-btn,
    .cancel-btn {
      position: absolute;
      width: 5em;
      height: 5em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 0.5em;
      border: 0.5em solid rgba(43, 131, 37, 1);
      box-sizing: border-box;

      &.save-btn {
        top: 2.5em;
        left: 6em;
      }

      &.cancel-btn {
        top: 2.5em;
        left: 0em;
      }

      &:hover {
        background-color: rgb(199, 199, 199);
      }

      svg {
        font-size: 3em;
        color: rgba(43, 131, 37, 1);
      }
    }
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: 1.75em;
    }

    .edit-input {
      font-size: 1.75em;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.5em;
      padding: 1em;
      margin: 0;
      margin-bottom: 1em;
      color: white;
      font-weight: 500;
      background-color: rgba($main-color,1);
    }

    p {
      font-size: 1.15em;
    }

    .edit-input {
      font-size: 1.5em;
    }

    .edit-textarea {
      font-size: 1.15em;
    }
  }

  @keyframes moutonTitleFadeIn {
    0% {
      opacity: 0;
      transform: translateY(-2em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes moutonTextFadeIn {
    0% {
      opacity: 0;
      transform: translateY(-2em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
