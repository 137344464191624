@import '../../variable.scss';

.contact {
    max-width: 80%;
    margin: 0 auto;
    padding: 2em;
    box-sizing: border-box;
    font-family: "adapt-variable", sans-serif;
    cursor: default;
    overflow: hidden;
  

    .form-contactInfo {
      display: flex;
      gap: 1em;
      margin-bottom: 2em;
      animation: formContactInfoFadeIn 1s ease forwards;
    
      .contact-info {
        width: 33%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .info {
          display: flex;
          flex-direction: column;
          gap: 0.40em;

          a {
            color: black;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .titre {
          margin-bottom: 1em;
          font-weight: 600;
          font-size: 1.25em;
        }

        img {
          width: 10em;
        }

        p {
          margin: 0;
          padding: 0;
        }
      }
      .contact-form {
        width: 66%;
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin-bottom: 2em;
    
        .form-group {
          display: flex;
          flex-direction: column;
    
          label {
            margin-bottom: 0.5em;
            font-weight: bold;
          }
    
          input, textarea {
            padding: 0.5em;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 1em;
          }
    
          textarea {
            resize: vertical;
            min-height: 150px;
          }
        }
    
        .btn-submit {
          font-family: "adapt-variable", sans-serif;
          font-variation-settings: "wght" 700, "wdth" 100;
          padding: 0.75em 1.5em;
          font-size: 1.25em;
          color: black;
          background-color: rgba($main-color,1);
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
    
          &:hover {
            color: white;
            background-color: rgb(80, 117, 1);
          }
        }
      }
  }
    .info-card {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2em;
        animation: infoCardFadeIn 3s ease forwards;
    }
  }
  
@keyframes formContactInfoFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-3em);

  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes infoCardFadeIn {
  0% {
    opacity: 0;
    transform: translateY(3em);

  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes contactH1FadeIn {
  0% {
    opacity: 0;
    transform: translateY(-3em);

  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

      .form-contactInfo {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;

        .contact-info {
        display: flex;
        flex-direction: row-reverse;
        width: 50%;
        gap: 1em;
        margin-top: 1em;

        img {
          width: 10em;
        }

        }

        .contact-form {
          width: 100%;
        }
    }

    .info-card {
      width: 100%;
  }
  }
}

@media (max-width: 768px) {
  .contact {
    max-width: 100%;
    padding: 0em 1em;

    h1 {
      font-size: 1.5em;
      padding: 1em;
      margin: 0;
      margin-bottom: 1em;
      color: white;
      font-weight: 500;
      background-color: rgba($main-color,1);
    }


      .form-contactInfo {
        width: 100%;

        .contact-info {
          width: 100%;
          display: flex;
          justify-content: center;

        img {
          display: none;
        }
      }
    }
  }
}
