@import '../../variable.scss';

.nos-valeurs {
  width: 80%;
  margin: 2em auto;
  overflow: hidden;
  font-family: "adapt-variable",sans-serif;
  font-variation-settings: 'wdth' 100, 'wght' 400;

  .dropdowns {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    position: relative;

    .btn-modifier {
      position: absolute;
      top: -7em;
      right: 0;
      width: 5em;
      height: 5em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      border: 0.5em solid rgba(43, 131, 37, 1);
      box-sizing: border-box;
     
        &:hover {
            background-color: rgb(199, 199, 199);
        }
        svg {
            font-size: 2.5em;
            color: rgba(43, 131, 37, 1);
        }
      }

    .dropdown {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      padding: 1.5em 0;
      background-color: rgba($main-color, 1);
      transition: background-color 0.3s ease;
      opacity: 0;

      &.right {
        animation: dropdownRightFadeIn 1s ease forwards;
      }

      &.left {
        animation: dropdownLeftFadeIn 1s ease forwards;
      }

      .title {
        border-bottom: 2px solid transparent;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.5em;
        color: black;
        font-family: "adapt-variable", sans-serif;
        font-variation-settings: "wght" 300, "wdth" 100;
        text-align: center;
        transition: color 0.5s ease;
      }

      &:hover {
        background-color: #f0f0f0;

        .title {
          color: black;
        }
      }

      &.expanded {
        background-color: #f0f0f0;
        
        .title {
          border-bottom: 2px solid black;
          color: black;
          padding-bottom: 2em;
        }

        .content {
          margin-top: 1.5em;
          opacity: 1;
        }
      }

      .content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s ease, opacity 0s ease, margin-top 0.5s ease;
        background-color: #f0f0f0;
        width: 100%;
        text-align: start;
        opacity: 0;
      }

      .content-inner {
        padding: 0 5%;
        font-size: 1em;
        line-height: 1.75;
      }
    }
  }

  .bold {
    font-weight: bold;
  }
}

@media (max-width: 1024px) {
  .nos-valeurs {
    width: 90%;
    margin: 2em auto;
    padding: 0;
    text-align: center;

    h2 {
      margin-top: 2em;
    }

    .dropdowns {
      .dropdown h3 {
        font-size: 1.5em;
      }
    }
  }
}

@media (max-width: 768px) {
  .nos-valeurs {
    width: 100%;
    margin: 2em auto;
    padding: 0;
    text-align: center;

    h2 {
      margin-top: 3em;
      font-size: 1.5em;
    }

    .dropdowns {
      .dropdown {
        .title {
        font-size: 1.25em;
        font-variation-settings: "wght" 200, "wdth" 100;
        }
      }
  
      .content-inner {
        font-size: 1em;
      }
    }
  }
}

@keyframes dropdownRightFadeIn {
  0% {
    opacity: 0;
    transform: translateX(2em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes dropdownLeftFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-2em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes varieteH2Fadein {
  0% {
    opacity: 0;
    transform: translateY(-3em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }  
}